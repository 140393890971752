<template>
    <div class="sj">
        <!-- fullpage 全屏滚动  -->
        <full-page ref="fullpage" :options="options" id="fullpage1">
            <div class="section bg">
                <div class="section-all">
                    <div class="sj__kj animate__animated animate__fadeInUp">
                        
                    </div>
                    <div class="sj__font animate__animated animate__fadeInUp">
                        
                        <span class="size64">司机招募</span>
                        <span class="size36">多种模式 总有一种适合你</span>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="section-all">
                    <div class="all-title">
                        <span class="size36">入职模式</span>
                        <span class="size16">Pattern</span>
                    </div>
                    <div class="join-mode">
                        <div class="join-mode__title">
                            <span class="size48 join-mode__span" :class="join">四种入职模式，总有一种适合你</span>
                        </div>
                        <div class="join-mode__list">
                            <div :class="['mode'+index,'join-mode__list-li',join]"
                             v-for="(item,index) in mode"
                             :key="index+'o'">
                                <div class="img">
                                    <div class="img__s" :style="{backgroundImage:`url(${item.img})`}"></div>
                                </div>
                                <div class="join-mode__list-font">
                                    <span class="join-mode__list-title size28">{{item.title}}</span>
                                    <p class="size20">
                                        {{item.memo}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="section">
                <div class="section-all">
                    <div class="all-title">
                        <span class="size36">加入我们</span>
                        <span class="size16">Join Us</span>
                    </div>
                    <div class="join-view">
                        <div class="join-view__content">
                            <div class="join-view__left">
                               <div class="join-view__img" :class="service"></div>
                            </div>
                            <div class="join-view__right" :class="servicefont">
                                <div class="join-view__right-font">
                                    <span class="size28" >
                                        完善的福利保障，灵活的入职模式期待您的加入！
                                    </span>
                                    <p class="size18">
                                        若感兴趣请填写您的真实信息，我们将尽快与您联系
                                    </p>
                                </div>
                                <div class="join-view__right-center">
                                    <div class="join-view__right-input">
                                        <input placeholder="姓名" class="size18" maxlength="10"
                                        v-model.trim="name"/>
                                    </div>
                                    <div class="join-view__right-input">
                                        <input placeholder="电话" class="size18" maxlength="11"
                                        v-model.trim="phone"/>
                                    </div>
                                </div>
                                <div class="join-view__right-btn" @click="submitJ">
                                    <span class="size18">提交</span>
                                </div>
                                <div class="driver-toast" v-if="toastStyle">
                                    <img src="../../static/images/toast-su.png" />
                                    <span class="size16">提交成功</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </full-page>
    </div>
</template>
<script>
import '../../static/css/fullpage.min.css';
import index from '../mobile/index.vue';
export default {
  components: { index },
    data(){
        return {
            options:{
                afterRender:this.afterRender,
                navigation:true,
                onLeave:this.onLeave
            },
            service:'',
            mode:[
                {
                    title:'专职驾驶员',
                    img:require('../../static/images/sjzm/r-one.png'),
                    memo:'国企平台、订单充足、多跑多赚、工作体面'
                },
                {
                    title:'低价合规网约车租赁',
                    img:require('../../static/images/sjzm/r-two.png'),
                    memo:'不限平台、不限流水、时间自由、用车自由'
                },
                {
                    title:'出租车驾驶员',
                    img:require('../../static/images/sjzm/r-three.png'),
                    memo:'国企平台、收入稳定、专业正规、工作自由'
                },
                {
                    title:'以租代购',
                    img:require('../../static/images/sjzm/r-four.png'),
                    memo:'不限平台、不限流水、时间自由、到期过户'
                }
            ],
            join:'',
            servicefont:'',
            name:'',
            phone:'',
            toastStyle:false,
            subIf:true
        }
    },
    mounted(){
        this.routerTo(2);
    },
    methods:{
        totastShow(){
            this.toastStyle = true;
            setTimeout(()=>{
              this.toastStyle = false;
            },2000)
        },
        afterRender(){},
        onLeave(e,n){
            const {index} = n;
            switch(index){
                case 1:
                  this.join = 'animate__animated animate__fadeInUp';
                  break;
                case 2:
                  this.service = 'animate__animated animate__fadeInLeft';
                  this.servicefont = 'animate__animated animate__fadeInRight';
                  break;
            }
        },
        submitJ(){
            const {name,phone} = this;
            if(name === ''){
                alert('请填写姓名');
                return;
            }
            if(phone === ''){
                alert('请填写手机号');
                return;
            }
            if(!(/^1[3456789]\d{9}$/.test(phone))){
                alert('手机号格式不对');
                return;
            }
            if(!this.subIf){
                return;
            }
            this.subIf = false;
            this.$http('get',{
                name:name,
                tel:phone
            }).then(res=>{
                this.subIf = true;
                if(res.data.returnCode == 10000){
                    this.name = '';
                    this.phone = '';
                    this.totastShow();
                }else{
                    alert(res.data.msg);
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
@import url('../../static/css/driver.less');
@import url('../../static/css/media.less');
</style>